import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'return-journey';
  sidebar: boolean = false;
  isBarIconOpenSub!: Subscription;
  loading: boolean = false;
  
  constructor(
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isBarIconOpenSub = this.appService.isBarIconOpen.subscribe({
      next: (response) => {
        this.sidebar = response;
      },
      error: (error) => {
        console.log('bar icons error ', error);
      }
    })


    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationError) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if(this.isBarIconOpenSub) this.isBarIconOpenSub.unsubscribe();
  }
}
