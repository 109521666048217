<div class="nav-container">
  <app-top-header></app-top-header>
</div>
<div class="main-body" [ngClass]="{'open': sidebar}">
  <app-side-menu></app-side-menu>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
  <!-- before downloading module this will trigger -->
  <div class="loader-wrapper" *ngIf="loading">
    <app-loader [size]="60">
    </app-loader>
  </div>
</div>
