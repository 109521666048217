import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './user-management/user-management.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: { title: 'Dashboard' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'user',
    loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
    data: { title: 'Users' },
  },
  {
    path: 'activity',
    loadChildren: () =>
      import('./activity/activity.module').then((m) => m.ActivityModule),
    data: { title: 'Activity' },
    canActivate: [AuthGuardService]

  },
  {
    path: 'activity-providers',
    loadChildren: () =>
      import('./activity-providers/activity-providers.module').then(
        (m) => m.ActivityProvidersModule
      ),
    data: { title: 'Activity Providers' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'city',
    loadChildren: () => import('./city/city.module').then((m) => m.CityModule),
    data: { title: 'City' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'state',
    loadChildren: () => import('./state/state.module').then((m) => m.StateModule),
    data: { title: 'State' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'itinerary',
    loadChildren: () =>
      import('./itinerary/itinerary.module').then((m) => m.ItineraryModule),
    data: { title: 'Itinerary' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'campus-ambassadors',
    loadChildren: () =>
      import('./campus-ambassadors/campus-ambassadors.module').then((m) => m.CampusAmbassadorsModule),
    data: { title: 'Campus Ambassadors' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'config-data',
    loadChildren: () =>
      import('./config-data/config-data.module').then((m) => m.ConfigDataModule),
    data: { title: 'Config Data' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'property',
    loadChildren: () =>
      import('./property/property.module').then((m) => m.PropertyModule),
    data: { title: 'Property' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'students',
    loadChildren: () =>
      import('./students/students.module').then((m) => m.StudentsModule),
    data: { title: 'Students' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'landmarks',
    loadChildren: () =>
      import('./landmarks/landmarks.module').then((m) => m.LandmarksModule),
    data: { title: 'Landmarks' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'clusters',
    loadChildren: () =>
      import('./clusters/clusters.module').then((m) => m.ClustersModule),
    data: { title: 'Clusters' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import('./announcements/announcements.module').then((m) => m.AnnouncementsModule),
    data: { title: 'Announcements' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'enquiries',
    loadChildren: () =>
      import('./enquiries/enquiries.module').then((m) => m.EnquiriesModule),
    data: { title: 'Enquiries' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    data: { title: 'Users' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'fixed-itinerary',
    loadChildren: () =>
      import('./fixed-itinerary/fixed-itinerary.module').then((m) => m.FixedItineraryModule),
    data: { title: 'Fixed Itinerary' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'group-iteneraries',
    loadChildren: () =>
      import('./group-itinerary/group-itinerary.module').then((m) => m.GroupItineraryModule),
    data: { title: 'Group Itinerary' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'fixed-itinerary-booking',
    loadChildren: () =>
      import('./fixed-itinerary-booking/fixed-itinerary-booking.module').then((m) => m.FixedItineraryBookingModule),
    data: { title: 'Fixed Itinerary Booking' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'activity-vendor',
    loadChildren: () =>
      import('./activity-vendor/activity-vendor.module').then((m) => m.ActivityVendorModule),
    data: { title: 'Activity Vendor' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'transport-vendor',
    loadChildren: () =>
      import('./transport-vendor/transport-vendor.module').then((m) => m.TransportVendorModule),
    data: { title: 'Transport Vendor' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'stay-pricing',
    loadChildren: () =>
      import('./stay-pricing/stay-pricing.module').then((m) => m.StayPricingModule),
    data: { title: 'All Season: Stay Pricing' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'blogs',
    loadChildren: () =>
      import('./blogs/blogs.module').then((m) => m.BlogsModule),
    data: { title: 'Blogs' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'stay',
    loadChildren: () =>
      import('./stay/stay.module').then((m) => m.StayModule),
    data: { title: 'Stay' },
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'home',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
