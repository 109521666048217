import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ConfigTable } from './datatable-filter-and-search/interfaces';
import { CommonHttpService } from '../common-http.service';

@Injectable()
export class SharedService {
  constructor(
    public readonly commonHttpService: CommonHttpService
  ) {
  }

  getDataFromApi(val: ConfigTable): Observable<any> {
    return this.commonHttpService.fetchDataForTable(val)
  }

  getCountOfData(val:ConfigTable, whereVal: any): Observable<any> {
    return this.commonHttpService.getTableDataCount(val, whereVal);
  }

  getTestAggregation(payload: any): Observable<any> {
    return this.commonHttpService.getTestAggregation(payload);
  }

  deleteRecord(val:ConfigTable, id: string): Observable<any> {
    return this.commonHttpService.deleteRecord(val, id);
  }
}
