import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { projectTables } from 'src/app/common/projectTable';
import { configTable$, copyObject } from '../behaviourSubject';
import { SharedService } from '../shared.service';
import { DatePipe } from '@angular/common';
import { reject } from 'ramda';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-datatable-filter-and-search',
  templateUrl: './datatable-filter-and-search.component.html',
  styleUrls: ['./datatable-filter-and-search.component.scss'],
  providers: [DatePipe]
})
export class DatatableFilterAndSearchComponent {

  @Input() flatModel: any = null;
  @Input() rows: number = 5;
  @Input() filterModel: any = null;
  @Input() pagination: boolean = true;
  @Input() start: number = 0;
  @Input() customPaginationStart: boolean = false;
  @Input() lazy: boolean = false;
  @Input() count: number = 0;

  headers = [] as string[];
  editLink = configTable$.value.valueEditPath;
  headersName = configTable$.value.fieldsName;
  tableData: any;
  matches = {} as any;
  isFlatable: boolean = false;
  isLoading: boolean = false;
  first = 0;
  whereVal: any = {}
  notFilter: boolean = true;
  configTable: any = configTable$;

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private messageService: MessageService
  ) { }


  ngOnInit() {
  }

  get getCountOfHeaders(): number {
    return this.headers.length;
  }

  private getDataFromAPI(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      this.isLoading = true;
      this.sharedService.getDataFromApi(configTable$.value).subscribe({
        next: (data: any) => {
          const tableData = projectTables(data, configTable$.value, this.router)
          this.tableData = tableData.data;
          this.headers = configTable$.value.fields;
          this.flatInit();
          if (this.filterModel !== null) {
            let newData: any[] = [];
            Object.entries(this.filterModel.filterModel).forEach((dt: any) => {
              this.tableData.forEach((data: any) => {
                if (data[dt[0]] === dt[1]) {
                  newData.push(data);
                }
              })
            })
            this.tableData = newData;
          }
          this.isLoading = false;
          resolve(true);
        },
        error: (error) => {
          this.isLoading = false;
          console.log("Error fetching data : ", error);
          reject(error)
        }
      })
    })
    return promise;
  }

  flatInit() {
    if (this.flatModel !== null) {
      this.isFlatable = true;
      Object.entries(this.flatModel.flatModel).forEach((dt: any) => {
        this.matches[dt[0].toString()] = { fstring: dt[1], data: dt[1].match(/{.+?}/g) as string[] }
      })
    }
  }

  flatten(obj: [any] | any, field: string): string {
    if (!Array.isArray(obj) || !this.isFlatable) {
      return obj;
    }

    if (typeof obj[0] === "string" || typeof obj[0] === "number") {
      return obj.join(", ")
    }

    let result = [] as string[];
    let field_arr = field.split("|");
    let final_field = field_arr[field_arr.length - 1]
    obj.forEach((data) => {
      let fstring = this.matches[final_field].fstring;
      this.matches[final_field].data.forEach((str: string) => {
        fstring = fstring.replaceAll(str, data[str.slice(1, str.length - 1)])
      })
      result.push(fstring);
    })
    return result.join(' | ');
  }

  onLazyLoading(event: any): void {
    let activeFilter = undefined;
    for (let key in event.filters) {
      const val = event.filters[key][0];
      if (val.value) {
        activeFilter = { name: key.replace('|', '.'), ...val }
      }
    }

    if (activeFilter && activeFilter.name) {
      activeFilter.name = activeFilter.name.replaceAll('|', '.');
    }

    const filter = JSON.parse(configTable$.value.whereFilter.replace('?filter=', ''));
    if (activeFilter) {
      const whereVal = this.createWhereCondition(activeFilter);
      configTable$.value.whereFilter = '?filter=' + JSON.stringify(
        {
          ...filter,
          skip: event.first,
          where: whereVal
        }
      )
    }
    else {
      this.start = 0;
      delete filter.where;
      configTable$.value.whereFilter = '?filter=' + JSON.stringify(
        {
          ...filter,
          skip: event.first
        }
      )
    }

    this.getDataFromAPI();
  }


  // Filters 
  async onChangeFilter(event: any): Promise<any> {
    let activeFilter = undefined;
    for (let key in event.filters) {
      const val = event.filters[key][0];
      if (val.value) {
        activeFilter = { name: key.replace('|', '.'), ...val }
      }
    }

    if (activeFilter && activeFilter.name) {
      activeFilter.name = activeFilter.name.replaceAll('|', '.');
    }

    const filter = JSON.parse(configTable$.value.whereFilter.replace('?filter=', ''));
    if (activeFilter) {
      this.start = 0;
      const whereVal = this.createWhereCondition(activeFilter);
      configTable$.value.whereFilter = '?filter=' + JSON.stringify(
        {
          ...filter,
          skip: 0,
          where: whereVal
        }
      )

      await this.getCountOfData(whereVal)
    }
    else {
      this.start = 0;
      delete filter.where;
      configTable$.value.whereFilter = '?filter=' + JSON.stringify(
        {
          ...filter,
          skip: 0
        }
      )

      await this.getCountOfData({})
    }
  }

  private getCountOfData(whereVal: any): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      this.sharedService.getCountOfData(configTable$.value, whereVal).subscribe({
        next: (response) => {
          this.count = response.count;
          resolve(true);
        }
      });
    })

    return promise;
  }

  private createWhereCondition(filter: any): any {
    const con: any = {};

    switch (filter.matchMode) {
      case "startsWith":
        con[filter?.name] = {
          "like": `^${filter.value}`,
          "options": "i"
        }
        break;
      case "contains":
        con[filter?.name] = {
          "like": `.*${filter.value}.*`,
          "options": "i"
        }
        break;
      case "notContains":
        con[filter?.name] = {
          "nlike": `.*${filter.value}.*`,
          "options": "i"
        }
        break;
      case "endsWith":
        con[filter?.name] = {
          "like": `${filter.value}$`,
          "options": "i"
        }
        break;
      case "equals":
        con[filter?.name] = {
          "eq": `${filter.value}`,
          "options": "i"
        }
        break;
      case "notEquals":
        con[filter?.name] = {
          "neq": `${filter.value}`,
          "options": "i"
        }
        break;
    }

    return con;
  }

  onCreateCopy(id: string): void {
    const actionName = this.configTable.value.actionName;
    if (actionName && id) {
      copyObject.next({ actionName: actionName, id: id })
      this.router.navigate(['../'], { relativeTo: this.activatedRoute })
    }
  }

  onClickDelete(id: string): void {
    const stauts = confirm("Are you sure you want delete blog");
    if(!stauts) return;
    const actionName = this.configTable.value.actionName;
    if (actionName && id) {
      this.sharedService.deleteRecord(configTable$.value, id).subscribe({
        next: (response: any) => {
          this.messageService.add({
            severity: "success",
            summary: "Record Deleted",
            detail: `Record successfully Deleted.`
          })
          this.getDataFromAPI();
        },
        error: (error: any) => {
          console.log("something went wrong ", error)
        }
      })
    }
  }

  formateDate(dateString: string): string {
    const date = this.datePipe.transform(dateString, 'dd/MM/yyyy hh:mm:ss a', 'UTC')
    return date || dateString;
  }
}
