<div class="multi-wrapper" #multiWrapper>
  <div class="top-wrapper input-text" 
    (click)="onClickOpenDropDown()">
    <div class="top-left">
      <ng-container *ngFor="let item of selectedOccasion; let i = index">
        <span>{{item.name}}<ng-container *ngIf="getSelectedSubCategory(i)">({{getSelectedSubCategory(i)}})</ng-container>, </span>
      </ng-container>
      <span *ngIf="!selectedOccasion || selectedOccasion.length <= 0" class="placeholder-title">Select Occasions</span>
    </div>
    <div class="top-right">
      <span class="pi pi-angle-down"></span>
    </div>
  </div>

  <div class="bottom-wrapper" *ngIf="isOpenDropDown">
    <div class="box" *ngFor="let item of occasionList; let i = index">
      <div class="box-left">
        <mat-checkbox 
          color="warn"
          (change)="onChangeCheckbox($event, i)"
          [checked]="isCheckedParent(i)"
        >
        </mat-checkbox>
      </div>
      <div class="box-right">
        <p>{{item.name}}</p>
        <div class="sub-category" *ngIf="item.additionalProp1.sub_category && isCheckedParent(i)">
          <ng-container *ngFor="let category of item.additionalProp1.sub_category; let j = index;">
            <p 
              (click)="onClickSubCategory(i, j)"
              [ngClass]="{'selected': isSelected(i, j)}"
            >
              {{category.name}}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>