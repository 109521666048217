<div class="backdrop" 
    (click)="onClickBackdrop()"
    *ngIf="sidebar"></div>

<div class="left-side-wrapper" [ngClass]="{'show-sidebar': sidebar}">
  <div class="sidebar-top">
    <ul 
      *ngIf="(loggedInUserData$ | async)?.token" 
      class="sidebar-menu"
    >
      <li (click)="onClickBackdrop()">
        <a class="sidebar-menu-item" 
          routerLink="/home"
          routerLinkActive="btn-active">
          <span class="fa fa-home"></span>
          <span>Dashboard</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="bottom-wrapper">
    <div class="bottom-item" (click)="onChangeMode()">
      <span class="pi" 
        [ngClass]="{'pi-moon': themeMode !== 'dark', 'pi-sun': themeMode === 'dark'}"
      ></span>
      <span>{{ themeMode == 'light' ? 'Dark' : 'Light' }}</span>
    </div>
    <!-- <div class="bottom-item"
      *ngIf="(loggedInUserData$ | async)?.token">
      <span class="pi pi-cog"></span>
      <span>Settings</span>
    </div> -->
    <div class="bottom-item" 
      *ngIf="(loggedInUserData$ | async)?.token"
      (click)="logout()">
      <span class="pi pi-sign-out"></span>
      <span>Log out</span>
    </div>
  </div>
</div>