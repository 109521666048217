<p-table 
  class="custom-table" 
  #dt1 
  [value]="tableData" 
  [paginator]="pagination" 
  [rows]="rows" 
  [(first)]="first"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [lazy]="lazy"
  [totalRecords]="count"
  (onLazyLoad)="onLazyLoading($event)"
  (onFilter)="onChangeFilter($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="headers[i]" style="width: 20%" *ngFor="let keys of headers; let i = index" style="width: 20%">
        <div>
          {{headersName[i]}}
          <p-columnFilter 
            type="text" 
            [field]="keys" 
            display="menu" 
            [showAddButton]="false"
            [matchModeOptions]="matchModeOptions"
            [matchMode]="'contains'"
            [showOperator]="false"
          >
          </p-columnFilter>
        </div>
      </th>
      <th>Actions</th>
    </tr>
    <div class="loader-wrapper" *ngIf="isLoading">
      <app-loader [size]="80">
      </app-loader>
    </div>
  </ng-template>
  <ng-template pTemplate="body" let-values>
    <tr>
      <td *ngFor="let keys of headers">
        {{ flatten(values[keys], keys) }}
      </td>
      <td class="action">
        <div>
          <a [routerLink]="values.routerLink" class="view">View</a>
          <span 
            *ngIf="configTable?.value?.copyFlag"
            (click)="onCreateCopy(values.id)" 
            class="copy">
            Copy
          </span>
          <span 
            *ngIf="configTable?.value?.deleteFlag"
            (click)="onClickDelete(values.id)"
            class="delete">
            Delete
          </span>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-headers>
    <tr class="emptymessage">
      <td [colSpan]="getCountOfHeaders + 1">
        No Records Found
      </td>
    </tr>
  </ng-template>
</p-table>

