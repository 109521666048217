import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProgressComponent } from './progress/progress.component';
import { DndDirective } from './dnd.directive';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ToastModule } from 'primeng/toast';
import { ChipsModule } from 'primeng/chips';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TagModule } from 'primeng/tag';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ImageModule } from 'primeng/image';
import { DatatableFilterAndSearchComponent } from './datatable-filter-and-search/datatable-filter-and-search.component';
import { SharedService } from './shared.service';
import { Calender, CalenderComponent } from './modules/calender/calender.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderComponent } from './loader/loader.component';
import { PreventScrollDirective } from './directive/prevent-scroll.directive';
import { OccasionsComponent } from './occasions/occasions.component';
import { CalendarModule } from 'primeng/calendar';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    DndDirective,
    ProgressComponent,
    FileUploaderComponent,
    DatatableFilterAndSearchComponent,
    LoaderComponent,
    PreventScrollDirective,
    OccasionsComponent
  ],
  imports: [
    FormsModule, 
    CommonModule, 
    TableModule, 
    RouterModule,
    ImageModule,
    ToastModule,
    MatCheckboxModule,
    CalendarModule,
    DragDropModule
  ],
  providers: [SharedService],
  exports: [
    ProgressComponent,
    FileUploaderComponent,
    DatatableFilterAndSearchComponent,
    LoaderComponent,
    OccasionsComponent,
    Calender,
    DndDirective,
    PreventScrollDirective,
    FormsModule,
    TableModule,
    ToastModule,
    ChipsModule,
    TagModule,
    ProgressBarModule,
    OverlayPanelModule,
    DialogModule,
    InputSwitchModule,
    MatCheckboxModule,
    CalendarModule,
    DragDropModule
  ],
})
export class SharedModule {}
