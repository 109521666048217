<nav class="nav-wrapper">
  <nav class="nav-left">
    <div class="bar-wrapper open" (click)="onToggleBar()"
      [ngClass]="{'open': isOpen}">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="brand-wrapper">
      <a routerLink="home">
        <img
          class="brand-logo" 
          src="assets/logo/logo.png" 
          alt="Avatar Logo" 
        />
      </a>
    </div>
  </nav>
  <div class="nav-right">
    <h3 class="header-title">{{ appService.title }}</h3>
  </div>
</nav>
