import { HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CommonHttpService } from 'src/app/common-http.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { getExtension } from 'src/app/common/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface FileProgress {
  file: File,
  progress: number;
  loaded: number;

}

type Files = {[key: string]: FileProgress};

export interface fileProgress {
  selectedFile: string;
  files: Files;
  uploaded: boolean;
  isRequestActive: boolean;
  uploadingError: boolean;
  isCompressed: boolean;
  isCompressing: boolean;
  selected: boolean;
  validationError: boolean;
  error: boolean;
  errorMessage: string;
}
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['../../shared/css/common-style.component.scss', './file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef | undefined;
  @Input() files: any[] = [];
  @Input() location: string = '';
  /**
   * size in Byte eg. 1KB == 1024 Byte
   * 100KB * 1024 = 102400
  */
  @Input() maxFileSize: number = 102400;
  @Input() allowedExtension: string[] = [".png", ".jpg", ".jpeg", ".webp", ".avif", ".gif"];
  @Output('fileUpdate') fileUpdate = new EventEmitter();
  selectedFiles: fileProgress[] = [];
  innerwidth: number = window.innerWidth;
  isSidebarOpen: boolean = false;
  barIconOpenSub!: Subscription;
  isUploading: boolean = false;
  selectAllFiles: boolean = false;
  isLoading: boolean = false;
  relativeIconPath: any = {
    ".xlsx": "../../../assets/xlsx.png",
    ".docx": "../../../assets/docx.png",
    ".csv": "../../../assets/csv.png",
    ".pdf": "../../../assets/pdf.png"
  }

  get isCompressing(): boolean {
    return Boolean(this.selectedFiles.find((item: fileProgress) => item.isCompressing));
  }

  get getSelectedFiles(): fileProgress[] {
    this.selectedFiles = this.selectedFiles.filter((item: fileProgress) => !item.uploaded);
    return this.selectedFiles;
  }

  isImage(name: string): boolean {
    return [".png", ".jpg", ".jpeg", ".webp", ".avif", ".gif"].includes(getExtension(name));
  }

  constructor(
    private readonly commonHttpService: CommonHttpService,
    private readonly appService: AppService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.barIconOpenSub = this.appService.isBarIconOpen.subscribe({
      next: (response) => {
        this.isSidebarOpen = response;
      }
    })
  }

  onFileDropped(event: any[]) {
    if (event.length <= 0) return;
    this.prepareFilesList(event);
  }

  fileBrowseHandler(event: any) {
    if (event?.target) {
      this.prepareFilesList(event.target.files);
    }
  }

  getFileName(file: string): string {
    const splitArr = file.split('.');
    return splitArr[0];
  }

  private prepareFilesList(files: Array<File>): void {
    for (const item of files) {
      const fileName = this.getFileName(item.name).replaceAll('webp', this.generateRandomString(5)).replaceAll('jpeg', this.generateRandomString(5)).replaceAll('png', this.generateRandomString(5)).replaceAll('jpg', this.generateRandomString(5)).replaceAll(" ", "_");
      const generatedFileName = `${fileName}_${this.generateRandomString(16)}${getExtension(item.name)}`;

      const modifiedFile = new File([item], generatedFileName, { type: item.type })
      let ext = getExtension(modifiedFile.name);

      if (!this.allowedExtension.includes(ext)) {
        this.displayError("Validation Error", `${ext} Invalid file extension: Please upload an image with a valid extension(e.g. ${this.allowedExtension.join(' ,')})`)
        continue;
      }

      const selectedFile = {
        selectedFile: URL.createObjectURL(modifiedFile),
        files: {
          defaultFile: {
            file: modifiedFile,
            progress: 0,
            loaded: 0,
          }
        },
        uploaded: false,
        isRequestActive: false,
        uploadingError: false,
        isCompressed: false,
        isCompressing: false,
        selected: false,
        validationError: false,
        error: false,
        errorMessage: '',
      }

      this.selectedFiles = [selectedFile, ...this.selectedFiles];
      this.selectAllFiles = false;
    }

    if (this.fileDropEl) {
      this.fileDropEl.nativeElement.value = '';
    }
  }

  //File uploading
  private uploadFile(item: fileProgress, fileProgress: FileProgress, location: string): Promise<string> {
    this.isUploading = true;
    const promise = new Promise<string>((resolve, reject) => {
      this.commonHttpService.fileUploader(fileProgress.file, location).subscribe({
        next: (event: any) => {
          item.isRequestActive = true;
          if (event.type === HttpEventType.UploadProgress) {
            fileProgress.progress = +Math.round((event.loaded / event.total) * 100)
            fileProgress.loaded = event.loaded;
            this.isUploading = true;
          }

          if (event.type === HttpEventType.Response) {
            resolve(event.body[0].imagePath)
          }
        },
        error: (error: any) => {
          item.uploadingError = true;
        }
      });
    })

    return promise;
  }

  async onClickUpload(): Promise<void> {
    this.selectedFiles.forEach(async (item: fileProgress, idx: number) => {
      const ext = getExtension(item.files['defaultFile'].file.name);
      if (!item.uploaded && !item.isRequestActive && (item.isCompressed || this.relativeIconPath[ext])) {
        if(this.isImage(item.files['defaultFile'].file.name)) {
          const [webpURL, jpegURL, jpgURL, pngURL] = await Promise.all([
            this.uploadFile(item, item.files['webp'], `${this.location}/webp`),
            this.uploadFile(item, item.files['jpeg'], `${this.location}/jpeg`),
            this.uploadFile(item, item.files['jpg'], `${this.location}/jpg`),
            this.uploadFile(item, item.files['png'], `${this.location}/png`),
          ])
          this.files.push(webpURL)
        } else {
          const hostedURL = await this.uploadFile(item, item.files['defaultFile'], this.location);
          this.files.push(hostedURL)
        }

        item.uploaded = true;
        this.isUploading = false;
        item.selected = false;
        this.fileUpdate.emit(this.files);
      }
    });
  }

  onClickCompressor(): void {
    let availableForCompress = 0;
    const allCompressedFiles = this.selectedFiles.filter((item: fileProgress) => item.isCompressed);
    const notCompressedFiles = this.selectedFiles.filter((item: fileProgress) => !item.isCompressed && item.selected);
    this.selectedFiles.forEach((item: fileProgress) => !item.selected && !item.isCompressed && ++availableForCompress);

    if (allCompressedFiles.length === this.selectedFiles.length && !this.isUploading) {
      this.messageService.add({
        severity: 'success',
        summary: `Ready To Upload`,
        detail: `All files have been compressed and are ready for upload.`,
      });
    }

    if (notCompressedFiles.length <= 0 && availableForCompress > 0) {
      this.displayError("File Not Selected", "Please select the file you want to compress.")
      return;
    }

    this.selectedFiles.forEach((item: fileProgress, idx: number) => {
      const fileName = item.files['defaultFile']?.file.name;
      if (item.isCompressed || !item.selected || item.isCompressing || !this.isImage(fileName)) {
        item.selected = false;
        return
      };
      this.imageConvertor(item, fileName)
    })
  }

  onSelected(index: number): void {
    const selectedFile = this.selectedFiles[index];
    selectedFile.selected = !selectedFile.selected;
    const flag = this.selectedFiles.find((item: fileProgress) => !item.selected);
    if (!flag && !this.selectAllFiles) {
      this.selectAllFiles = true;
    }
    else if (flag && this.selectAllFiles) {
      this.selectAllFiles = false;
    }
  }

  convertKbToMb(size: number): string {
    let kb = size / 1024;
    let mb = kb / 1024;
    let data = '';
    if (kb < 1024) {
      data = kb.toFixed(2) + "KB";
    } else if (kb >= 1024 && mb <= 1024) {
      data = mb.toFixed(2) + 'MB';
    }

    return data;
  }

  splitFileName(fileName: string): string {
    let len = 160;
    let sideBarHeight = this.isSidebarOpen ? 200 : 80;
    let width = this.innerwidth - sideBarHeight - 64 - 150 - 64 - 100;
    if (this.innerwidth <= 500) {
      width += 220
    }
    else if (this.innerwidth <= 650) {
      width += 110;
    }

    len = width / 7.56;
    let splitArray = fileName?.split('.');
    let modifiedStr = fileName;
    if (fileName?.length > len + splitArray[1].length) {
      modifiedStr = splitArray[0].substring(0, len) + '...' + splitArray[1];
    }

    return modifiedStr;
  }

  isSubstringInMiddle(str: string, sub: string): boolean {
    let index = str.indexOf(sub);
    return index > 0 && index + sub.length < str.length;
  }


  async deleteAllImage(index: number, file: string) {
    // this.displayError("Error", "Failed to delete the image. Please try again or check if the file is in use.")
    this.isLoading = true;
    const allPromise = this.isSubstringInMiddle(file, "webp") ? ["webp", "jpeg", "png", "jpg"].map((item:string) => this.deleteImage(file.replaceAll("webp", item))) : [this.deleteImage(file)];
    const [webp, jpeg, png, jpg] = await Promise.all(allPromise);
    if(webp) {
      this.messageService.add({ 
        severity: 'error',
        summary: `Deleted`,
        detail: `Image deleted successfully.`,
      });
      this.files.splice(index, 1);
      this.fileUpdate.emit(this.files);
      this.isLoading = false;
    }
  }

  deleteImage(file: string): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.commonHttpService.fileDelete(file).subscribe({
        next: (response: any) => {
          resolve(true)
        }, 
        error: (error: any) => {
          reject(error)
        }
      })
    })

    return promise;
  }

  onChangeCheckbox(): void {
    if (this.selectAllFiles) {
      this.selectedFiles.forEach((item: fileProgress) => {
        item.selected = true;
      })
    } else {
      this.selectedFiles.forEach((item: fileProgress) => {
        item.selected = false;
      })
    }
  }

  onClickDelete(): void {
    this.selectedFiles = this.selectedFiles.filter((item: fileProgress) => !item.selected);
  }

  toggleCheckBox(): void {
    this.selectAllFiles = !this.selectAllFiles;
    this.onChangeCheckbox();
  }

  getFileUrl(file: File): string {
    const ext: string = getExtension(file.name); 
    return this.relativeIconPath[ext] ||  '../../../assets/image.png';
  }

  getUploadedFileUrl(name: string): string {
    const ext = getExtension(name);
    return this.relativeIconPath[ext];
  }

  @HostListener('window:resize', ['$event'])
  onResizeWindow(event: any): void {
    this.innerwidth = window.innerWidth;
  }

  onDropDay(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    this.fileUpdate.emit(this.files)
  }

  async imageConvertor(item: fileProgress, fileName: string): Promise<void> {
    item.isCompressing = true;
    const maxWidth = 1600;
    const maxHeight = 800;
    const [ webpFile, jpegFile, jpgFile, pngFile ]= await Promise.all([
      this.convertImage(item.files['defaultFile'].file, fileName, 'webp', maxWidth, maxHeight),
      this.convertImage(item.files['defaultFile'].file, fileName, 'jpeg', maxWidth, maxHeight),
      this.convertImage(item.files['defaultFile'].file, fileName, 'jpg', maxWidth, maxHeight),
      this.convertImage(item.files['defaultFile'].file, fileName, 'png', maxWidth, maxHeight) 
    ])

    item.files = {
      ...item.files,
      webp: {
        file: webpFile,
        progress: 0,
        loaded: 0,
      },
      jpeg: {
        file: jpegFile,
        progress: 0,
        loaded: 0,
      },
      jpg: {
        file: jpgFile,
        progress: 0,
        loaded: 0,
      },
      png: {
        file: pngFile,
        progress: 0,
        loaded: 0,
      }
    };
    item.isCompressed = true;
    item.isCompressing = false;
    item.selected = false;
  }

  convertImage(file: File, fileName: string, convertTo: string, maxWidth: number, maxHeight: number): Promise<File> {
    const promise = new Promise<File>((resolve, reject) => {
      if(!file) reject(null);
      const reader = new FileReader();
      const canvas = document.createElement('canvas')
      reader.onload = (e) => {
        const processedImageDataUrl = reader.result as string;
        const img = new Image();
        img.onload = () => {
          const ctx = canvas.getContext('2d');
          if(ctx) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            const {width, height} = this.calculateDimensions(img.width, img.height, maxWidth, maxHeight, true)
            canvas.width = width;
            canvas.height = height;
            ctx.fillStyle="#FFFFFF";
            ctx.drawImage(img, 0, 0, width, height);
            const processedImageDataUrl = canvas.toDataURL(`image/${convertTo}`);
            fileName = fileName.replace(/\.[^/.]+$/, "") + `.${convertTo}`
            const file = this.dataURLToFile(processedImageDataUrl, fileName);
            resolve(file)
          }
        };
        img.src = processedImageDataUrl;
      };
      reader.readAsDataURL(file);
    })

    return promise;
  }


  dataURLToFile(dataURL: string, fileName: string): File {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }

    const blob =  new Blob([uint8Array], { type: contentType });
    return new File([blob], fileName, { type: blob.type });
  }

  calculateDimensions( originalWidth: number, originalHeight: number, maxWidth: number, maxHeight: number, maintainAspectRatio: boolean): { width: number; height: number } {
    let width = originalWidth;
    let height = originalHeight;
  
    if (maintainAspectRatio) {
      const aspectRatio = originalWidth / originalHeight;
      if (width > maxWidth) {
        width = maxWidth;
        height = width / aspectRatio;
      }
  
      if (height > maxHeight) {
        height = maxHeight;
        width = height * aspectRatio;
      }
    } else {
      width = Math.min(width, maxWidth);
      height = Math.min(height, maxHeight);
    }
  
    return {
      width: Math.round(width),
      height: Math.round(height)
    };
  }

  getConvertedFile(files: Files): string[] {
    if(this.isImage(files['defaultFile'].file.name) && Object.keys(files).length > 1) {
      return Object.keys(files).filter((item: string) => item !== 'defaultFile');
    }
    return ['defaultFile'];
  }

  generateRandomString(length = 16): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  private displayError(title: string, error: string): void {
    this.messageService.add({
      severity: "error",
      summary: title,
      detail: error
    })
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    if (this.barIconOpenSub) this.barIconOpenSub.unsubscribe();
  }
}