import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { login$ } from '../shared/behaviourSubject';
import { Subscription, Observable } from 'rxjs';
import { LoginResponseInterface } from '../interfaces/login.interface';
import { UserManagementService } from '../user-management/user-management.service';
import { DOCUMENT } from '@angular/common';
import { AppService } from '../app.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  themeMode: string = 'light';
  checked: boolean = false;
  sidebar: boolean = false;
  barIconOpenSub!: Subscription;


  constructor(
    private readonly userManagementService: UserManagementService,
    @Inject(DOCUMENT) private ducument: Document,
    private appService: AppService
  ) {
  }

  loggedInUserData$: Observable<LoginResponseInterface> = new Observable();
  ngOnInit() {
    let storage = localStorage.getItem('isDark');
    if(storage) {
      storage = JSON.parse(storage);
      if(storage) {
        this.themeMode = 'dark';
        this.checked = true;
      }
      else {
        this.themeMode = 'light';
        this.checked = false;
      }

      this.applyTheme();
    }
    this.loggedInUserData$ = login$.asObservable()

    this.barIconOpenSub = this.appService.isBarIconOpen.subscribe({
      next: (response) => {
        this.sidebar = response;
      },
      error: (error) => {
        console.log('bar icons error ', error);
      }
    })
  }

  onClickBackdrop(): void {
    if(window.innerWidth < 650) {
      this.sidebar = !this.sidebar;
    }
    this.appService.isBarIconOpen.next(this.sidebar)
  }

  logout() {
    this.onClickBackdrop();
    this.userManagementService.logout()
  }

  onChangeMode(): void {
    this.themeMode = this.themeMode === 'dark' ? 'light' : 'dark';
    this.applyTheme();
  }
  
  private applyTheme(): void {
    const body = this.ducument.body.classList;
    if(this.themeMode === 'dark') {
      body.remove('light');
      body.add(this.themeMode);
      localStorage.setItem('isDark', JSON.stringify(true));
    }
    else {
      body.remove('dark');
      body.add(this.themeMode);  
      localStorage.setItem('isDark', JSON.stringify(false));
    }
  }


  // destorying subscription
  ngOnDestroy(): void {
      if(this.barIconOpenSub) {
        this.barIconOpenSub.unsubscribe();
      }
  }
}
