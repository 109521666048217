import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class AppService {
  title = ''
  isBarIconOpen = new Subject<boolean>();
  thingsToCarry = [
    "Hat",
    "Boots",
    "Waterproof Trekking Shoes",
    "Pair of Socks",
    "Water Bottle",
    "Gloves",
    "Snacks",
    "Camera",
    "Lip Balm",
    "Umbrella",
    "Ear Muffs",
    "Suncreen",
    "Flip - Flops",
    "Moisturizer",
    "Woolen cap",
    "Thermal wears",
    "Windcheaters",
    "Raincoat/Poncho",
    "Extra pair of clothes",
    "Sunglasses",
    "Day Pack",
    "Rucksacks",
  ]
}

