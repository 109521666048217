import { Router } from '@angular/router';
import * as R from 'ramda';
import { ConfigTable } from '../shared/datatable-filter-and-search/interfaces';
export const projectTables = (arrayValue:any, configTable: ConfigTable, router: Router)=>{
  const getFinalArr = R.map((x => {
    const getValues = R.fromPairs(R.map((k)=>{
      const sepArr = R.split('|', k);
      return [k,R.path(sepArr,x)]
    },configTable.fields))
    return { routerLink: `/${configTable.urlPath}${configTable.urlString}/${x.id}`, ...getValues }
  }), arrayValue)
  return {headers: R.filter(x=> !R.includes(x,["routerLink"]),R.keys(getFinalArr[0])) as string[],data:getFinalArr};
}
