export const makeid = (length: number, preString: string): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return preString + result;
};


export const getExtension = (name: string): string  => {
  let ext:any = name?.split(".");
  return ext = ext?.length > 1 ? `.${ext[ext?.length - 1]}` : '';
}