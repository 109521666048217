import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserManagementService } from 'src/app/user-management/user-management.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: UserManagementService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.auth.getToken();
    const body = req.body;
    this.trimObjectKeysValue(body);

    const authReq = req.clone({
      setHeaders: { Authorization: `Bearer ${authToken}` },
      body: body
    });

    return next.handle(authReq).pipe(
      catchError((err) => {
        if (
          [401].includes(err.status) &&
          err.error.error.name === 'UnauthorizedError'
        ) {
          // auto logout if 401 or 403 response returned from api
          this.auth.logout();
        }
        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      })
    );
  }

  private trimObjectKeysValue(data: any): void {
    for(let key in data) {
      if(data.hasOwnProperty(key)) {
        if(data[key] !== null && typeof data[key] === "object") {
          this.trimObjectKeysValue(data[key]);
        } else if(typeof data[key] === "string") {
          data[key] = data[key]?.trim();
        }
      }
    }
  }
}