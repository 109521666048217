import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CalendarOptions, DatePointApi } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FullCalendarModule } from "@fullcalendar/angular";
import interactionPlugin from "@fullcalendar/interaction";
import { DATE_RANGE, UTC, INCLUDE_DAY_RANGE, fromString, config, DAYS, fromNumber } from "./util";
import { CommonModule } from '@angular/common';

enum STATE {
  OPEN,
  CLOSED
}

interface configMonth {
  "month": number,
  "closing": Array<number | Array<number>>
  "exceptionalOpen": Array<number | Array<number>>
}

interface config {
  "year": number,
  "values": Array<configMonth>
}

export interface YearValues {
  month: number;
  closing: number[];
  exceptionalOpen: number[]
}

export interface Calender {
  year: number;
  values: YearValues[]
}


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  @Input() calenderValue: any = [];
  @Output() outputCalenderValues: EventEmitter<any> = new EventEmitter<any>();
  savedText = "Calender saved Successfully!!!"
  savedFlag = false;
  Dates = new Map<string, STATE>();
  rangeDates = new Array<Date>();
  isRangeSelection: boolean = false;
  beEvents: Array<any> = [];
  currentMonth: number | null = null;
  currentYear: number | null = null;
  weird_count: number = 0;
  rangeStart: string = '';
  rangeEnd: string = '';
  calendarOptions: CalendarOptions = {
    timeZone: "UTC",
    initialView: "dayGridMonth",
    showNonCurrentDates: false,
    dateClick: this.handleDateClick.bind(this),
    events: [],
    displayEventTime: false,
    plugins: [dayGridPlugin, interactionPlugin],
    datesSet: (arg) => {
      this.currentMonth = arg.start.getUTCMonth() + 1;
      this.currentYear = arg.start.getUTCFullYear();
      this.weird_count += 1;
      let dd = document.getElementsByClassName("fc-day");
      for (let i = 0; i < 7; i++) {
        // @ts-ignore
        let element = dd.item(i).firstChild;
        // @ts-ignore
        let type = fromString(element.firstChild["ariaLabel"].toString());
        // @ts-ignore
        (this.weird_count === 1) && element.addEventListener("click",
          () => {
            let days: Array<Date> = INCLUDE_DAY_RANGE(type, this.currentMonth, this.currentYear);
            days.forEach(value => {
              if ((!this.Dates.has(value.toString())) || (this.Dates.get(value.toString()) === STATE.OPEN)) {
                this.Dates.set(value.toString(), STATE.CLOSED);
                this.addClosure(UTC(value.toString()));
              }
              else {
                this.Dates.set(value.toString(), STATE.OPEN);
                this.removeClosure(UTC(value.toString()));
              }
            });
            this.calendarOptions.events = [...this.beEvents];
          }
        )
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
    if (Object.keys(this.calenderValue).length > 0) {
      this.setConfig(this.calenderValue)
    }
  }

  addClosure(_start: Date) {
    this.beEvents.forEach(value => {
      if (value.id === _start.toString()) {
        return;
      }
    });
    this.beEvents.push({ title: "Closed", start: _start, color: "#ff0000", id: _start.toString() });
  }

  removeClosure(_start: Date) {
    let newEvents: Array<any> = [];
    this.beEvents.forEach(value => {
      if (value.id !== _start.toString()) {
        newEvents.push(value);
      }
    })
    this.beEvents = newEvents;
  }

  range(): void {
    this.isRangeSelection = !this.isRangeSelection
  }

  log(): void {
    this.Dates.forEach((value, key) => {
      console.log(key + " : " + value.toString());
    });
  }

  stateDivByDayType(day_type: DAYS | null, month: number, year: number, state: STATE): void {
    let dd = document.getElementsByClassName("fc-day");
    for (let i = 0; i < 7; i++) {
      // @ts-ignore
      let element = dd.item(i).firstChild;
      // @ts-ignore
      let type = fromString(element.firstChild["ariaLabel"].toString());
      if (type === day_type) {
        let days: Array<Date> = INCLUDE_DAY_RANGE(type, month + 1, year);
        days.forEach(value => {
          this.Dates.set(value.toString(), state);
        });
      }
    }
  }

  setConfig(config: Calender[]): void {
    Object.entries(config).forEach((item: any) => {
      const currentYear = item[0];
      Object.entries(item[1]).forEach((rangeObj: any) => {
        const currentMonth = rangeObj[0];
        const rangeList = rangeObj[1];
        rangeList.forEach((range: any) => {
          DATE_RANGE(new Date(range.closedFrom), new Date(range.closedTo)).forEach(date => {
            this.Dates.set(date.toString(), STATE.CLOSED);
          });
        })
      })
    })

    this.Dates.forEach((value, key) => {
      if (value === STATE.CLOSED) {
        this.addClosure(UTC(key));
      }
    });
    this.calendarOptions.events = [...this.beEvents];
  }

  daysBetween(date1: Date, date2:Date) {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    
    const diffTime = Math.abs(time2 - time1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

  convertToDate(str: string): string {
    const date = new Date(str);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  generateConfig(): void {
    const result: any = {};
    let allDates: string[] = [];
    this.Dates.forEach((value, key) => {
      if(value) allDates.push(this.convertToDate(key));
    })
    allDates = allDates.sort();
    let rangeStart = new Date(allDates[0]);
    let prevStart = rangeStart;
    for(let i = 1; i < allDates.length; ++i) {
      const rangeEnd = new Date(allDates[i]);
      if(this.daysBetween(prevStart, rangeEnd) == 1) {
        prevStart = rangeEnd;
      } else {
        const range = {
          closedFrom: this.convertToDate(rangeStart.toString()),
          closedTo: this.convertToDate(prevStart.toString())
        }

        const year = rangeStart.getFullYear().toString();
        const month = rangeStart.getMonth().toString()
        if(!result[year]) result[year] = {};
        if(!result[year][month]) result[year][month] = [];
        result[year][month].push(range)
        rangeStart = rangeEnd;
        prevStart = rangeStart;
      }
    }

    if(rangeStart && prevStart) {
      const range = {
        closedFrom: this.convertToDate(rangeStart.toString()),
        closedTo: this.convertToDate(prevStart.toString())
      }
      const year = rangeStart.getFullYear().toString();
      const month = rangeStart.getMonth().toString()
      if(!result[year]) result[year] = {};
      if(!result[year][month]) result[year][month] = [];
      
      result[year][month].push(range)
    }

    this.outputCalenderValues.emit(result);
    this.savedFlag = true;
    setTimeout(() => {
      this.savedFlag = false;
    }, 2000);
  }

  private singleDateClick(info: DatePointApi): void {
    this.rangeStart = '';
    this.rangeEnd = '';
    if (this.Dates.get(info.date.toString()) === STATE.CLOSED) {
      this.beEvents = [];
      this.Dates = this.Dates.set(info.date.toString(), STATE.OPEN);
      this.Dates.forEach((value, key) => {
        if (value === STATE.CLOSED) {
          this.addClosure(UTC(key));
        }
      });
      this.calendarOptions.events = [...this.beEvents];
    } else {
      this.Dates = this.Dates.set(info.date.toString(), STATE.CLOSED);
      this.addClosure(UTC(info.date.toString()));
      this.calendarOptions.events = [...this.beEvents];
    }
  }

  private rangeDateClick(info: DatePointApi): void {
    if (this.rangeDates.length === 1) {
      this.rangeEnd = info.dateStr;
      let [_start] = this.rangeDates;
      let _end = UTC(info.date.toString())
      let dateRange = DATE_RANGE(_start, _end);
      dateRange.forEach(date => {
        if ((!this.Dates.has(date.toString())) || (this.Dates.get(date.toString()) === STATE.OPEN)) {
          this.Dates.set(date.toString(), STATE.CLOSED);
          this.addClosure(UTC(date.toString()));
        }
        else {
          this.Dates.set(date.toString(), STATE.OPEN);
          this.removeClosure(UTC(date.toString()));
        }
      });
      this.calendarOptions.events = [...this.beEvents];
      this.rangeDates = [];
    }
    else {
      this.rangeDates.push(UTC(info.date.toString()));
      this.rangeStart = info.dateStr;
      this.rangeEnd = '';
    }
  }

  handleDateClick(info: DatePointApi): void {
    if (!this.isRangeSelection) {
      this.singleDateClick(info);
    }
    else {
      this.rangeDateClick(info);
    }
  }
  
}
@NgModule({
  imports: [
    FullCalendarModule, 
    CommonModule
  ],
  exports: [
    CalenderComponent
  ],
  declarations: [
    CalenderComponent
  ]
})
export class Calender { }
