export enum ApiPathEnum {
  Countries = '/countries',
  Regions = '/regions',
  States = '/states',
  StayCategories = '/staycategories',
  Stays = '/stays',
  Files = '/files2S3',
  Ocassions = '/ocassions',
  TypeOfStay = '/typeOfStay',
  Personalities = '/personalities',
  Activities = '/activities',
  Activitycategories = '/activitycategories',
  ActivitityProviders = '/activity-providers',
  Cities = '/cities',
  TransportType = '/config-data',
  Config = '/config-data',
  AnnouncementConfig = '/announcementType',
  EnquiryConfig = '/enquiryStatusType',
  Transport = '/transports',
  PriceOptions = '/config-data',
  Users = '/users',
  CampusAmbassadors = '/campus-ambassadors',
  Count = '/count',
  Restaurants = '/restaurant',
  Itnaries ='/itnaries',
  Properties = '/properties',
  PropertyConfigs = '/property-configs',
  PropertyThatApplies = '/propertyThatApplies',
  PropertyKind = '/propertyKind',
  PropertyFacilities = '/propertyFacilities',
  FoodOptions = '/foodOptions',
  SelectMenu = '/selectMenu',
  Lang = '/en',
  Facilities = '/facilities',
  Policies = '/policies',
  ChooseRoomType = '/chooseRoomType',
  ChooseBedType = '/chooseBedType',
  ChooseRoomView = '/chooseRoomView',
  ChooseRoomFacilities = '/chooseRoomFacilities',
  Rooms = '/rooms',
  Prices = '/prices',
  RoomBeds = '/room-beds',
  Students = '/students',
  StudentsList = '/users/list',
  Landmarks = '/landmarks',
  Announcements = '/anouncements',
  Clusters = '/clusters',
  Enquiries = '/enquiries',
  UserRoles = '/userroles',
  Rewards = '/rewards',
  GetPoints = '/getPoints',
  GroupType = '/grouptype',
  ReferralSessions = '/referral-sessions',
  MaximumNightForBooking= '/maximumNightForBooking',
  AcceptedPaymentMethods= '/acceptedPaymentMethods',
  Leaderboards = '/users/leaderboards',
  Property = '/property',
  GenerateRoomDashboard = '/generateRoomDashboard',
  CustomerDiscountPolicy = '/customerDiscountPolicy',
  PublicItineraries='/public-itineraries',
  PublicChildItineraries='/public-child-itineraries',
  GroupIteneraries='/group-iteneraries',
  PredefineItinerybookings='/predefine-itinerybookings',
  PublicIteneraryGroupTag='/publicIteneraryGroupTag',
  ItineraryCartStatusType='/itineraryCartStatusType',
  ActvityVendorRegistration='/actvityVendorRegistration',
  TransportVendorRegistrations='/transport-vendor-registrations',
  TestAggregation = '/testAggregation',
  StayPricing = '/stay-pricing',
  Blogs='/blogs',

  //Stay
  Region='/region',
  State='/state',
  City='/city',
  Landmark='/landmark',
  Address='/address',
  PropertyContact='/propertyContact',
  Image='/image',
  PropertyType='/propertytype',
  RoomType='/roomType',
  PropFacilities='/propFacilities',
  PropImage='/propImage',
  PropertyPolices='/propertyPolices',
  Roomview='/roomview',
  Beds='/beds',
  RoomImage='/roomImage',
  Activity='/activity',
  PropActivity='/propactivity',
  Occasion='/occasion',
  RoomFacility='/roomfacilities',
  Status='/status'
}
