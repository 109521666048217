import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonHttpService } from 'src/app/common-http.service';

@Component({
  selector: 'app-occasions',
  templateUrl: './occasions.component.html',
  styleUrls: ['../css/common-style.component.scss', './occasions.component.scss']
})
export class OccasionsComponent implements OnInit {
  occasionList: any[] = [];
  @Input() selectedOccasion: any[] = [];
  isOpenDropDown: boolean = false;
  @ViewChild('multiWrapper') multiWrapper!: ElementRef;
  @Output() onChangeDropdown = new EventEmitter<any[]>();

  constructor(
    private commonHttpService: CommonHttpService
  ) { }

  ngOnInit(): void {
    this.getOccasions();
  }

  getOccasions(): void {
    this.commonHttpService.getOcassions().subscribe({
      next: (response: any) => {
        this.occasionList = response?.filter((item: any) => item.id !== "63d8016c4dedc4ed5b30d7f1");
      },
      error: (error: any) => {
        console.log("featching occasions Errror: ", error);
      }
    })
  }

  onChangeCheckbox(event: any, index: number): void {
    const selected = this.occasionList[index];
    if (!event?.checked) {
      this.selectedOccasion = this.selectedOccasion?.filter((item: any) => item.id !== selected.id);
    } else {
      this.selectedOccasion?.push({
        id: selected.id,
        name: selected.name
      })
    }

    this.onChangeDropdown.emit(this.selectedOccasion);
  }


  isCheckedParent(index: number): boolean {
    const selected = this.occasionList[index];
    const result = this.selectedOccasion?.find((item: any) => item.id === selected.id);
    return result;
  }

  onClickSubCategory(parentIndex: number, childIndex: number): void {
    const selected = this.occasionList[parentIndex];
    const selectedSubCategory = selected.additionalProp1.sub_category[childIndex]
    const result = this.selectedOccasion?.find((item: any) => item.id === selected.id);


    if (!result?.subCategory || result?.subCategory?.length <= 0) {
      result.subCategory = [];
      result.subCategory?.push({
        id: selectedSubCategory.id,
        name: selectedSubCategory.name,
      })
      return;
    }

    const subCategoryResult = result.subCategory?.find((item2: any) => item2.id === selectedSubCategory.id);
    if (subCategoryResult) {
      result.subCategory = result.subCategory?.filter((item2: any) => item2.id !== selectedSubCategory.id);
    } else {
      result.subCategory?.push({
        id: selectedSubCategory.id,
        name: selectedSubCategory.name,
      })
    }
  }

  onClickOpenDropDown(): void {
    this.isOpenDropDown = !this.isOpenDropDown;
  }

  isSelected(parentIndex: number, childIndex: number): boolean {
    const selected = this.occasionList[parentIndex];

    let flag = false;
    this.selectedOccasion?.forEach((item: any) => {
      if (item.id === selected.id) {
        item.subCategory?.forEach((item2: any) => {
          if (item2.id === selected.additionalProp1.sub_category[childIndex].id) {
            flag = true;
          }
        })
      }
    })

    return flag;
  }

  getSelectedSubCategory(index: number): string {
    return this.selectedOccasion[index]?.subCategory?.map((item: any) => item.name).join(', ');
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this.multiWrapper.nativeElement.contains(event.target)) {
      this.isOpenDropDown = false;
    }
  }
}
