import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Subscription, Observable } from 'rxjs';
import { LoginResponseInterface } from '../interfaces/login.interface';
import { login$ } from '../shared/behaviourSubject';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnDestroy, OnInit{
  isOpen:boolean = false;
  barIconOpenSub!: Subscription;


  constructor(public readonly appService: AppService) { }
  loggedInUserData$: Observable<LoginResponseInterface> = new Observable();
  ngOnInit() {
    this.loggedInUserData$ = login$.asObservable()
    this.barIconOpenSub = this.appService.isBarIconOpen.subscribe({
      next: (response) => {
        this.isOpen = response;
      },
      error: (error) => {
        console.log('error ', error);
      }
    })
  }

  onToggleBar(): void {
    this.isOpen = !this.isOpen;
    this.appService.isBarIconOpen.next(this.isOpen)
  }


  ngOnDestroy(): void {
    if(this.barIconOpenSub) {
      this.barIconOpenSub.unsubscribe();
    }
  }
}
