import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import * as R from 'ramda';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPathEnum } from '../enums/api-path.enum';
import {
  LoginRequestInterface,
  LoginResponseInterface,
} from '../interfaces/login.interface';
import { login$ } from '../shared/behaviourSubject';

@Injectable()
export class UserManagementService {
  private readonly baseUrl = environment.baseUrl;
  constructor(
    private readonly http: HttpClient,
    private router: Router,
    private readonly localStorageService: LocalStorageService
  ) {}
  login(val: LoginRequestInterface): Observable<any> {
    return this.http.post<LoginRequestInterface[]>(
      `${this.baseUrl}${ApiPathEnum.Users}/login`,
      val
    );
  }
  logout() {
    this.localStorageService.removeItem('t');
    this.localStorageService.removeItem('u');
    login$.next({} as LoginResponseInterface);
    this.router.navigateByUrl('/user/login');
  }
  refreshToken() {
    if (this.isUserLoggedIn()) {
      const t = this.localStorageService.getItem('t');
      this.http
        .post<any>(`${this.baseUrl}${ApiPathEnum.Users}/refreshToken`, {
          refreshToken: t,
        })
        .subscribe({
          next: (res) => {
            this.localStorageService.setItem('t', res.accessToken);
          },
        });
    }
  }
  getToken() {
    return this.localStorageService.getItem('t');
  }
  isUserLoggedIn(): boolean {
    return R.isNotNil(this.localStorageService.getItem('t'));
  }
  setUserInStorage(val: LoginResponseInterface) {
    setTimeout(this.refreshToken, 600000);
    this.localStorageService.setItem('u', btoa(JSON.stringify(val)));
  }
  getUserInStorage(): LoginResponseInterface {
    return JSON.parse(
      atob(this.localStorageService.getItem('u'))
    ) as LoginResponseInterface;
  }
}
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private readonly userManagementService: UserManagementService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    //check some condition
    if (!this.userManagementService.isUserLoggedIn()) {
      //redirect to login/home page etc
      //return false to cancel the navigation
      this._router.navigateByUrl('user/login');
    }
    login$.next(this.userManagementService.getUserInStorage());
    return true;
  }
}
@Injectable()
export class LocalStorageService {
  setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  removeItem(key: string): any {
    return localStorage.removeItem(key);
  }

  setBool(key: string, value: boolean) {
    localStorage.setItem(key, String(value));
  }

  getBool(key: string): boolean {
    return localStorage.getItem(key) === 'true';
  }

  setObject(key: string, value: object) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
