import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPathEnum } from './enums/api-path.enum';
import {
  CategoriesInterface,
  CityInterface,
  RegionInterface,
  StateInterface,
} from './interfaces/common.interface';
import { ConfigTable } from './shared/datatable-filter-and-search/interfaces';

@Injectable()
export class CommonHttpService {
  private readonly baseUrl = environment.baseUrl;
  // @Input() location: string = '';
  constructor(private readonly http: HttpClient) {}

  // TODO: At this moment this id is hardcoded in future it would be dynamic
  getRegionByCountry(
    id: string = '6346e3023fb461632d1a2a40'
  ): Observable<RegionInterface[]> {
    return this.http.get<RegionInterface[]>(
      `${this.baseUrl}${ApiPathEnum.Countries}/${id}/regions`
    );
  }

  getStateByRegion(id: string): Observable<StateInterface[]> {
    return this.http.get<StateInterface[]>(
      `${this.baseUrl}${
        ApiPathEnum.Regions
      }/${id}/states?filter=${JSON.stringify({
        offset: 0,
        limit: 100,
        skip: 0,
        order: 'name',
      })}`
    );
  }

  getCityByState(id: string): Observable<CityInterface[]> {
    return this.http.get<CityInterface[]>(
      `${this.baseUrl}${
        ApiPathEnum.States
      }/${id}/cities?filter=${JSON.stringify({
        offset: 0,
        limit: 100,
        skip: 0,
        order: 'name',
      })}`
    );
  }

  getStayCategories(): Observable<CategoriesInterface[]> {
    return this.http.get<CategoriesInterface[]>(
      `${this.baseUrl}${ApiPathEnum.StayCategories}`
    );
  }

  getCities(): Observable<CityInterface[]> {
    return this.http.get<CityInterface[]>(
      `${this.baseUrl}${ApiPathEnum.Cities}`
    );
  }

  getOcassions(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}${ApiPathEnum.Ocassions}`);
  }

  getPersonalities(): Observable<any> {
    return this.http.get<any[]>(`${this.baseUrl}${ApiPathEnum.Personalities}`);
  }

  getActivityCategories() {
    return this.http.get<any[]>(
      `${this.baseUrl}${ApiPathEnum.Activitycategories}`
    );
  }

  getActivities() {
    return this.http.get<any[]>(`${this.baseUrl}${ApiPathEnum.Activities}`);
  }

  fileUploader(file: any, location: string) {
    let formData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${this.baseUrl}${ApiPathEnum.Files}/?location=${location}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getStatuses() {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.Config}/grouptype/propertyStatus/lang/en`
    );
  }

  getActivityFormData(id: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.ActivitityProviders}/${id}`
    );
  }

  getTransportTypeData(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.TransportType}/grouptype/transportType/lang/en`
    );
  }

  getConfigdataGroups(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.baseUrl}${ApiPathEnum.Config}/groups`
    );
  }

  getRecommendationLogic(type: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.Config}/grouptype/recommendationLogic${type}/lang/en`
    );
  }

  getRecommendationParams(type: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.Config}/grouptype/recommendationParams${type}/lang/en`
    );
  }

  getPriceOptions(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.PriceOptions}/grouptype/priceOptions/lang/en`
    );
  }

  getPlaceDataByName(val: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${ApiPathEnum.Cities}/getplaces/searchByName/${val}`
    );
  }

  fetchDataForTable(val: ConfigTable): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}${val.apiPathName}${val.whereFilter}${val.searchId}`
    );
  }

  getStates(): Observable<StateInterface[]> {
    return this.http.get<StateInterface[]>(
      `${this.baseUrl}${ApiPathEnum.States}`
    );
  }

  getTableDataCount(val: ConfigTable, whereVal: any): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/${val.actionName}/count?where=${JSON.stringify(
        whereVal
      )}`
    );
  }

  getTestAggregation(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}${ApiPathEnum.TestAggregation}`, payload);
  }

  deleteRecord(val: ConfigTable, id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${val.actionName}/${id}`);
  }
}
