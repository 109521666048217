<div class="calender-wrapper">
  <div class="calender-left">
    <full-calendar [options]="calendarOptions"></full-calendar>
  </div>
  <div class="calender-right">
    <div class="save-wrapper">
      <button (click)="range()" class="go-back">Select Range</button>
      <button (click)="generateConfig()" class="save-and-exit">Save Calender</button>
    </div>
    <p>Range Selection: {{this.isRangeSelection}}</p>
    <ng-container *ngIf="savedFlag">
      <span class="success-text">{{savedText}}</span>
    </ng-container>

    <ng-container *ngIf="rangeStart !== '' || rangeEnd !== ''">
      <div class="success-text gap">
        <span *ngIf="rangeStart !== ''">Selections From {{rangeStart}}</span>
        <span *ngIf="rangeEnd !== ''">- To {{rangeEnd}}</span>
      </div>
    </ng-container>
  </div>
</div>

