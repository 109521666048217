import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[type="number"][preventScroll]'
})
export class PreventScrollDirective {

  constructor() { }

  @HostListener('wheel', ['$event'])
  preventScroll(event: WheelEvent): void {
    event.preventDefault();
  }
}
